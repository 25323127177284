<template>
  <div>
    <page-header :title="`Dashboard`">
      <template v-slot:breadcrumbs>
        <li>Dashboard</li>
      </template>

      <template v-slot:actions>
        <div></div>
      </template>
    </page-header>

    <div class="row mt-5">
      <div class="col-3">
        <div class="card card-raised border-start border-success border-5 widget">
          <div class="card-body px-4">
            <div class="d-flex justify-content-between align-items-center mb-2">
              <div class="me-2">
                <div class="number">200</div>
                <div class="card-text">Clientes</div>
              </div>
              <div class="icon-circle bg-success text-white">
                <i class="la la-users" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-3">
        <div class="card card-raised border-start border-secondary border-5 widget">
          <div class="card-body px-4">
            <div class="d-flex justify-content-between align-items-center mb-2">
              <div class="me-2">
                <div class="number">200</div>
                <div class="card-text">Condomínios</div>
              </div>
              <div class="icon-circle bg-secondary text-white">
                <i class="la la-city" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-3">
        <div class="card card-raised border-start border-primary border-5 widget">
          <div class="card-body px-4">
            <div class="d-flex justify-content-between align-items-center mb-2">
              <div class="me-2">
                <div class="number">200</div>
                <div class="card-text">Imóveis</div>
              </div>
              <div class="icon-circle bg-primary text-white">
                <i class="la la-home" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-3">
        <div class="card card-raised border-start border-warning border-5 widget">
          <div class="card-body px-4">
            <div class="d-flex justify-content-between align-items-center mb-2">
              <div class="me-2">
                <div class="number">200</div>
                <div class="card-text">Usuários</div>
              </div>
              <div class="icon-circle bg-warning text-white">
                <i class="la la-user" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="bg-white mt-5">
      <div class="card">
        <div class="card-body p-4">
          <div class="d-flex align-items-center justify-content-between">
            <h5 class="card-description">Últimos clientes cadastrados</h5>
            <a href="#" @click.prevent="$router.push('/clientes')">Ver todos</a>
          </div>
          <div class="table-responsive mt-3">
            <table class="table table-striped">
              <thead>
                <tr>
                  <th>Nome</th>
                  <th>Criado em</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Imobiária Sub100</td>
                  <td>10/10/2022 15:36</td>
                  <th>
                    <div class="d-flex align-items-center justify-content-end">
                      <button class="btn btn-sm btn-primary"><i class="la la-eye" /></button>
                    </div>
                  </th>
                </tr>

                <tr>
                  <td>Imobiária Sub100</td>
                  <td>10/10/2022 15:36</td>
                  <th>
                    <div class="d-flex align-items-center justify-content-end">
                      <button class="btn btn-sm btn-primary"><i class="la la-eye" /></button>
                    </div>
                  </th>
                </tr>

                <tr>
                  <td>Imobiária Sub100</td>
                  <td>10/10/2022 15:36</td>
                  <th>
                    <div class="d-flex align-items-center justify-content-end">
                      <button class="btn btn-sm btn-primary"><i class="la la-eye" /></button>
                    </div>
                  </th>
                </tr>

              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import PageHeader from "@/components/PageHeader.vue";
export default {
  name: 'dashboard',
  props: {},
  components: {
    PageHeader
  },
  data() {
    return {}
  }
}
</script>
<style lang="scss" scoped>

.widget {
  .number {
    font-size: 1.3rem;
    font-weight: 700;
    color: #333;
  }

  .card-text {
    font-size: 1.1rem;
  }
}

.icon-circle {
  height: 3rem;
  width: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;

  i {
    font-size: 1.8rem;
  }

}
</style>
